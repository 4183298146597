@import "./variables";

html {
  overflow-y: hidden; // hide vertical
  scroll-behavior: smooth !important;
}

body {
  scroll-behavior: smooth !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

a {
  text-decoration: none; /* no underline */
}

h2 {
  font-size: $h2-size !important;
  font-weight: 500 !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.icon-instagram-default {
  display: block;
  position: relative;
  width: $icon-instagram-width;
  height: $icon-instagram-height;
  cursor: pointer;
}

.icon-instagram-default:hover {
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  h2 {
    font-size: 25px !important;
    padding-bottom:5px!important;
  }
}