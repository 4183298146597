

$icon-instagram-width: 40px;
$icon-instagram-height: 40px;
$h2-size: 25px;

// Breakpoints
$bp-small: 767px;
$bp-tablet: 991px;
$bp-computer: 1199px;
$bp-large: 1919px;
$bp-wide: 1920px;

// Media Queries
$mq-small: "(max-width: #{$bp-small})";
$mq-tablet: "(max-width: #{$bp-tablet})";
$mq-computer: "(max-width: #{$bp-computer})";
$mq-large: "(max-width: #{$bp-large})";
$bp-wide: "(min-width: #{$bp-wide})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";


@media #{$bp-wide} {
  $icon-instagram-width: $icon-instagram-width * 1.5!global;
  $icon-instagram-height: $icon-instagram-height * 1.5!global;
  $h2-size: $h2-size * 1.4!global;
}

