.nav-bar-menu {
  background-color: #ededef !important;
  padding: 0 20px;
  top: 0px !important;
  border: none !important;
  z-index: 1000 !important;
  position: static !important;
  border-radius: 0px !important;
}

.nav-bar-menu-items {
  color: #111010;
}

.nav-bar-menu-items:hover {
  color: red !important;
}

.logo-name {
  color: #8a9493;
  font-size: 25px;
  letter-spacing: 5px;
  padding-left: 10px;
  padding-top: 2px;
}

.logo-container {
  flex-direction: initial;
  display: flex;
  justify-content: center;
}

.logo-container:hover {
  background-color: #ededef !important;
}

.nav-bar-menu-language,
.nav-bar-menu-language:focus,
.nav-bar-menu-language:hover,
.nav-bar-menu-language:active {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.nav-bar-language {
  cursor: pointer;
  border: none;
  padding: 0px;
  margin: 0px;
}

.nav-bar-language > img {
  width: 25px;
  height: 15px;
  display: flex;
  justify-content: center;
}

.mobile-nav-bar {
  display: none;
}

@media only screen and (max-width: 767px) {
  .nav-bar-menu-language {
    display: none !important;
  }

  /*menu hamburger*/
  .mobile-view {
    padding: 10px;
    opacity: 1;
    transform: translateX(20px);
    transition-delay: 0.3s;
    height: 55px;
    display: block;
  }

  .menu-icon-wrapper {
    height: 35px;
    display: block;
    cursor: pointer;
    margin-left: -57px;
  }

  #hamburger {
    position: absolute;
    top: 10px;
    width: 60px;
    height: 45px;
    cursor: pointer;
    transform: rotate(0deg) scale(0.5);
    transition: 0.5s ease-in-out;
  }

  #hamburger span {
    display: block;
    position: absolute;
    height: 9px;
    width: 50%;
    background: black;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  #hamburger span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  #hamburger span:nth-child(odd) {
    left: 0px;
    border-radius: 9px 0 0 9px;
  }

  #hamburger span:nth-child(1),
  #hamburger span:nth-child(2) {
    top: 0px;
  }

  #hamburger span:nth-child(3),
  #hamburger span:nth-child(4) {
    top: 18px;
  }

  #hamburger span:nth-child(5),
  #hamburger span:nth-child(6) {
    top: 36px;
  }

  #hamburger.open span:nth-child(1),
  #hamburger.open span:nth-child(6) {
    transform: rotate(45deg);
  }

  #hamburger.open span:nth-child(2),
  #hamburger.open span:nth-child(5) {
    transform: rotate(-45deg);
  }

  #hamburger.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }

  #hamburger.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }

  #hamburger.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  #hamburger.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  #hamburger.open span:nth-child(5) {
    left: 5px;
    top: 29px;
  }

  #hamburger.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 29px;
  }

  .mobile-nav-bar {
    display: block;
    z-index: 100;
  }

  .nav-bar-menu {
    box-shadow: none !important;
    margin: 0rem -1rem !important;
    /*display: none!important;*/
  }

  .nav-bar-menu-items,
  .nav-bar-language {
    display: none !important;
  }

  .nav-bar-sticky {
    background-color: #ededef !important;
  }

  .footer-container-desc {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*sidebar*/
  .mobile-close-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 50;
    top: 55px;
    transition: 0.3s;
    left: -175px;
  }

  .mobile-open-menu {
    position: fixed;
    height: 100vh;
    width: 150px;
    z-index: 50;
    top: 55px;
    left: -42px;
    background: #ededef !important;
    overflow-x: hidden;
    transition: 0.3s;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .mobile-sidebar-menu {
    padding-top: 20px !important;
  }

  .logo-menu-item {
    position: absolute;
    right: 0;
  }

  .logo-container {
    display: block;
    position: relative;
    width: 100%;
    cursor: pointer;
    padding-left: 135px;
    height: 55px;
  }

  .mobile-nav-bar-language {
    width: 40px;
    height: 25px;
  }
}

@media only screen and (max-width: 300px) {
  .logo-menu-item {
    position: relative;
    right: -50%;
  }
}
